import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Switch from '@mui/material/Switch';
import { Button, Dialog, DialogContent, MenuItem, Select, Slide, Table, TableBody, TableCell, TableContainer, TableRow, TextField } from '@mui/material';
import useTableSorting from '../../Hooks/useTableSorting';
import { PROVIDER_LIST, UPDATE_PROVIDER } from '../../Repository/ProviderRepo';
import { toast } from 'react-toastify';
import Loading from '../../Component/Loading';
import { RoleTitle, permissionMessage } from '../../Constants/Constants';
import PaginationCom from '../../Component/pagination';
import MoreVertIcon from '@mui/icons-material/MoreVert'
import NoRecordFound from '../../Component/NoRecordFound';
import AddIcon from '@mui/icons-material/Add';
import { ASSIGN_FREE_PLAN, ASSIGN_PLAN, EXTEND_FREE_PLAN, PLAN_LIST } from '../../Repository/SubcripstionPlanRepo';
import { SelectBox } from '../../Component/Inputs';
import { Errors } from '../../Component/ErrorShow';
import { useFormik } from 'formik';
import { assignPlanValidation, extendFreePlanValidation, pattern } from '../../Validation/Validation';
import { getlocalStorage, intialRole } from '../../utils';
import { RESEND_INVITATION_EMAIL_TEAM, } from '../../Repository/TeamManagementRepo';
import { Modal } from 'react-bootstrap';
import moment from 'moment-timezone';
import useMinimumDate from '../../Hooks/useMinimumDate';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
const List = () => {
    const naviget = useNavigate()
    const [providerList, setProviderList] = useState([])
    const [subscriptionPlanList, setSubscriptionPlanList] = useState([])
    const [loading, setLoading] = useState(false)
    const { state } = useLocation();
    const [paginationTotalCount, setPaginationTotalCount] = useState(0)
    const [changeEmail, setChangeEmail] = useState(false)
    const [controller, setController] = useState(state === null ? { page: 1, rowsPerPage: 10, searchPage: null } : state?.controller);
    const userData = getlocalStorage("userInfodata")
    const [Filter, setFilter] = useState({
        archive: true,
        provider: "",
        disable_button: true
    })
    const [openModal, setOpenModal] = useState({
        assignPlan: false,
        assignFreePlan: false,
        extendFreePlan: false,
        archive: false,
        resendInvitaion: false,
        data: null
    })
    const [assignFreePaln, setAssignFreePaln] = useState({
        endDate: "",
        error: ""
    })
    const headCells = [
        { id: "firstName", numeric: true, disablePadding: false, label: "  Provider Name", isShorted: true },
        { id: "email", numeric: true, disablePadding: false, label: " Email Address", isShorted: true },
        { id: "mobile", numeric: true, disablePadding: false, label: " Mobile Number", isShorted: true },
        { id: "invitationStatus", numeric: true, disablePadding: false, label: " Invitation Status", isShorted: true },
        { id: "#", numeric: true, disablePadding: false, label: " Status", isShorted: false, showLeft: false, },
        { id: "Action", numeric: true, disablePadding: false, label: " Action", isShorted: false, showLeft: true, },
    ];
    const { EnhancedTableHead, order, orderBy, stableSort, getComparator, handleRequestSort } = useTableSorting(headCells)
    const { minimumdate } = useMinimumDate()
    async function getSubscriptionList() {
        const payload = {
            activeStatus: true,
        };
        setLoading(true)
        try {
            let response = await PLAN_LIST(payload)
            setSubscriptionPlanList(response?.data)
            setLoading(false)
        } catch (error) {
            setSubscriptionPlanList([])
            toast.error(error.message)
            setLoading(false)
        }
    }

    async function getProviderList() {
        setLoading(true)
        const { page, rowsPerPage, searchPage } = controller;
        const { archive, provider } = Filter;
        const keyword = provider.trim();
        const payload = {
            keyword: (searchPage && keyword) ? keyword : undefined,
            page: page,
            count: rowsPerPage,
            activeStatus: archive,
            rolesToList: [RoleTitle?.SERVICE_PROVIDER],
            getStaffList: false,
        };

        try {
            let response = await PROVIDER_LIST(payload)
            setProviderList(response?.data)
            setPaginationTotalCount(response?.totalCount)
        } catch (error) {
            setProviderList([])
            if (error.message !== "No provider found") toast.error(error.message)
            setPaginationTotalCount(0)
        } finally {
            setLoading(false)
        }
    }

    const assignSubscriptionPlan = useFormik({
        initialValues: {
            subscriptionPlan: ""
        },
        validationSchema: assignPlanValidation,
        onSubmit: async (values, { resetForm }) => {
            let payload = {
                providerId: userData?.userId,
                userId: openModal?.data?.userId,
                subscriptionPlanId: values?.subscriptionPlan,
                [userData?.rolesAndPermission?.roleName === RoleTitle?.SUPER_ADMIN ? "superAdminId" : "staffId"]: userData?.userId,
                roleTitle: userData?.rolesAndPermission?.roleName,
                startDate: moment().startOf('day'),
                endDate: moment().add(1, 'month').endOf('month'),
                paymentMethod: "cash",
                paymentStatus: "Completed",
                paymentDate: moment().startOf('day')
            }
            setLoading(true)
            try {
                let response = await ASSIGN_PLAN(payload)
                toast.success(response.message)
                setLoading(false)
                handleClosedModal("assignPlan")
                getProviderList()
                resetForm()
            } catch (error) {
                toast.error(error.message)
                setLoading(false)
            }
        }
    })
    const handleassignfreePlan = async () => {


        if (!assignFreePaln?.endDate) {
            setAssignFreePaln((prev) => ({ ...prev, error: "Please choose  plan end date" }))
            return true
        }
        let payload = {
            [userData?.rolesAndPermission?.roleName === RoleTitle?.SUPER_ADMIN ? "superAdminId" : "staffId"]: userData?.userId,
            roleTitle: userData?.rolesAndPermission?.roleName,
            freeTrialDaysNum: 30,
            providerId: openModal?.data?.userId,
            endDate: moment(assignFreePaln?.endDate).endOf("day").utc()

        }
        setLoading(true)
        try {
            let response = await ASSIGN_FREE_PLAN(payload)
            toast.success(response.message)
            setLoading(false)
            handleClosedModal("assignFreePlan")
            getProviderList()
            setAssignFreePaln({
                error: "",
                endDate: ""
            })
        } catch (error) {
            toast.error(error.message)
            setLoading(false)
        }
    }
    const resendInvitationFun = useFormik({
        initialValues: {
            oldEmail: "",
            newEmail: "",
            name: "",
            staffId: "",
            mobile: ""
        },
        validateOnChange: true,
        validate: (values) => {
            const errors = {};
            if (changeEmail) {
                if (!values?.newEmail) {
                    errors.newEmail = "Please enter new email address ";
                } else if (!values?.newEmail?.match(pattern?.EMAIL)) {
                    errors.newEmail = "Please enter correct email ";
                }
                else {
                    delete errors.newEmail
                }
            }
            return errors;
        },
        onSubmit: async (value) => {
            let payload = {
                userId: value?.userId,
                providerId: value?.staffId,
                newEmail: changeEmail ? value?.newEmail : value?.oldEmail,
                firstName: value?.name,
            }
            setLoading(true)
            try {
                let response = await RESEND_INVITATION_EMAIL_TEAM(payload)
                toast.success(response.message)
                setLoading(false)
                handleClosedModal("resendInvitaion")
                getProviderList()
            } catch (error) {
                toast.error(error.message)
                handleClosedModal("resendInvitaion")
                setLoading(false)
            }
        }
    })
    const extendFreePlanFun = useFormik({
        initialValues: {
            endDate: "",
            remark: "",
        },
        validationSchema: extendFreePlanValidation,
        onSubmit: async (value) => {
            let payload = {
                ...value,
                endDate: moment(value?.endDate).utc(),
                assignedSubscriptionPlanId: openModal?.data?.assignedSubscriptionPlanId,
            }
            setLoading(true)
            try {
                let response = await EXTEND_FREE_PLAN(payload)
                toast.success(response.message)
                setLoading(false)
                handleClosedModal("extendFreePlan")
                getProviderList()
            } catch (error) {
                toast.error(error.message)
                handleClosedModal("extendFreePlan")
                setLoading(false)
            }
        }
    })
    const Invitation = (row) => {
        if (row?.invitationStatus === "Pending") {
            handleOpenModal("resendInvitaion", row);
            resendInvitationFun.setFieldValue("oldEmail", row?.email);
            resendInvitationFun.setFieldValue("staffId", row?.userId)
            resendInvitationFun.setFieldValue("name", row?.firstName)
            resendInvitationFun.setFieldValue("mobile", row?.mobile)
        }
    }


    const handleChangeStatus = async (data) => {
        const { userId, activeStatus } = data
        let payload = {
            activeStatus: !activeStatus,
            providerId: userId,
        }
        setLoading(true)
        try {
            let response = await UPDATE_PROVIDER(payload)
            toast.success(response.message)
            setLoading(false)
            if (providerList?.length === 1) {
                setController((pre) => ({ ...pre, page: controller.page > 1 ? controller.page - 1 : 1 }))
            } else getProviderList()

        } catch (error) {
            toast.error(error.message)
            setLoading(false)
        }
    }

    const handleClosedModal = (type) => {
        if (type === "assignPlan") {
            assignSubscriptionPlan.resetForm()
        }
        if (type === "resendInvitaion") {
            resendInvitationFun.resetForm()
        }
        if (type === "extendFreePlan") {
            extendFreePlanFun.resetForm()
        }
        if (type === "assignFreePlan") {
            setAssignFreePaln({
                error: "",
                endDate: ""
            })
        }
        setOpenModal((pre) => ({
            ...pre,
            data: null,
            [type]: false
        }))
    }

    const handleOpenModal = (type, data) => {
        if (type === "assignPlan") {
            assignSubscriptionPlan.setFieldValue("subscriptionPlan", data?.subscriptionPlanId)
            getSubscriptionList()
        }
        setOpenModal((prev) => ({
            ...prev,
            data,
            [type]: true,
        }));
    };

    const handleSubmit = async (event, type) => {
        if (event === "YES") {
            if (type === "assignPlan") assignSubscriptionPlan.handleSubmit()
            if (type === "assignFreePlan") handleassignfreePlan()
        } else {
            handleClosedModal(type)
        }
    }
    useEffect(() => {
        getProviderList()
        naviget({ replace: true, state: null })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [controller])

    const handleChangeFilter = (event) => {
        const { name, value } = event.target
        setFilter((pre) => ({ ...pre, disable_button: false, [name]: value }))
    }
    const reset = () => {
        setController((pre) => ({ ...pre, page: pre.searchPage ?? pre.page, searchPage: null }))
        setFilter({ archive: true, provider: "", disable_button: true })
    }
    const HandleSearchList = () => {
        setController((pre) => ({ ...pre, searchPage: pre?.page, page: 1 }))
    }
    return (
        <>
            {loading && <Loading />}
            <div className="container-fluid h-100 d-flex flex-column justify-content-between">
                <div>
                    <div className="row">
                        <div className="col-12 px-0">
                            <h3 className='PAGE-NAME'>Provider</h3>
                        </div>
                    </div>
                    <section className='d-flex justify-content-between row'>
                        <div className='col-10  col-xxl-10 col-xl-9 col-lg-8   px-0 col-12'>
                            {
                                intialRole({ permission: "Provider LIST", userData: userData, section: 'AbletoView/ListLeads' }) ?
                                    <div className="row">
                                        <div className="col-lg-4 col-sm-6 ">
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                className='w-100'
                                                size='small'
                                                value={Filter?.archive}
                                                name='archive'
                                                onChange={handleChangeFilter}
                                            >
                                                <MenuItem value={true}>Active</MenuItem>
                                                <MenuItem value={false}>In-Active</MenuItem>
                                            </Select>
                                        </div>
                                        <div className=" mt-3 mt-sm-0 col-lg-4 col-sm-6">
                                            <TextField id="outlined-basic" name='provider' value={Filter?.provider} onChange={handleChangeFilter} placeholder='Search provider.. ' variant="outlined" className='w-100' size='small' />
                                        </div>
                                        <div className="mt-3 mt-lg-0 col-lg-4 d-flex justify-content-lg-start  justify-content-end">
                                            <Button variant="contained" className='me-2 mui_button_color' disabled={Filter?.disable_button} onClick={HandleSearchList}>search</Button>
                                            <Button variant="contained" className='mui_button_color' disabled={Filter?.disable_button} onClick={reset}>reset</Button>
                                        </div>
                                    </div>
                                    : null
                            }
                        </div>
                        {
                            intialRole({ permission: "Provider LIST", userData: userData, section: 'AbletoaddProvider' }) ? <div className='col-xxl-2 col-xl-3 col-lg-4 col-12 d-flex justify-content-end pe-0 mt-3 mt-lg-0'>
                                <Button variant="contained" className='mui_button_color' startIcon={<AddIcon />} onClick={() => naviget("create-provider")}>
                                    Create provider
                                </Button>
                            </div> : null
                        }
                    </section>
                    {
                        intialRole({ permission: "Provider LIST", userData: userData, section: 'AbletoView/ListLeads' }) ?
                            <>
                                <div className="row">
                                    {
                                        providerList?.length === 0 && <div className="col-sm-12 mt-3 text-center " >
                                            <NoRecordFound />
                                        </div>
                                    }
                                </div>
                                <TableContainer className=" mt-4"  >
                                    <Table
                                        id="table_mui"
                                        aria-labelledby="tableTitle"
                                        size={"medium"}
                                        aria-label="enhanced table">
                                        {
                                            <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={providerList.length} />
                                        }
                                        <TableBody>
                                            {stableSort(providerList, getComparator(order, orderBy)).map(
                                                (row, index) => {
                                                    const labelId = `enhanced-table-checkbox-${index}`;
                                                    return <React.Fragment key={row?.userId}>
                                                        <TableRow id="hover_tr" >
                                                            <TableCell className="ps-3" component="th" id={labelId} scope="row" padding="none" >
                                                                {`${row?.firstName} ${row?.lastName}`}
                                                            </TableCell>
                                                            <TableCell id={labelId} align="left" >{row?.email ? row?.email : "-"}</TableCell>
                                                            <TableCell id={labelId} align="left" >{row?.mobile}</TableCell>
                                                            <TableCell id={labelId} align="left" > {row?.invitationStatus} </TableCell>
                                                            <TableCell id={labelId} align="left" ><Switch size='small' onClick={(e) => handleChangeStatus(row)} checked={row?.activeStatus} />  </TableCell>
                                                            <TableCell id={labelId} align="right" >
                                                                <MoreVertIcon
                                                                    type="button"
                                                                    data-bs-toggle="dropdown"
                                                                    aria-expanded="false"
                                                                />
                                                                <ul className="dropdown-menu">
                                                                    {
                                                                        intialRole({ permission: "Provider LIST", userData: userData, section: 'AbletoEditProviderInformation' }) ?
                                                                            <li>
                                                                                <button className={`dropdown-item `} onClick={() => naviget(`edit-provider/${row?.userId}`)}  >Edit Provider</button>
                                                                            </li> : null
                                                                    }
                                                                    {
                                                                        row?.assignedSubscriptionPlanId === null &&
                                                                        <li>
                                                                            <button className={`dropdown-item `} onClick={() => handleOpenModal("assignFreePlan", row)}  >Assign Free Plan</button>
                                                                        </li>
                                                                    }
                                                                    {
                                                                        (row?.assignedSubscriptionPlanId && row?.isFreePlan) &&
                                                                        <li>
                                                                            <button className={`dropdown-item `} onClick={() => handleOpenModal("extendFreePlan", row)}  >Extend Free Plan</button>
                                                                        </li>
                                                                    }
                                                                    <li>
                                                                        <button className={`dropdown-item `} onClick={() => handleOpenModal("assignPlan", row)}  >Assign Plan</button>
                                                                    </li>
                                                                    {
                                                                        row?.assignedSubscriptionPlanId && <li>
                                                                            <button className={`dropdown-item `} onClick={() => naviget(`view-plan/${row?.assignedSubscriptionPlanId}`)}  >View Plan</button>
                                                                        </li>
                                                                    }

                                                                    {
                                                                        row?.assignedSubscriptionPlanId && <li>
                                                                            <button className={`dropdown-item `} onClick={() => naviget(`staff-list/${row?.userId}`)}  >Staff </button>
                                                                        </li>
                                                                    }
                                                                    {
                                                                        row?.assignedSubscriptionPlanId && <li>
                                                                            <button className={`dropdown-item `} onClick={() => naviget(`location-list/${row?.userId}`)}  >Location </button>
                                                                        </li>
                                                                    }

                                                                    {
                                                                        row?.assignedSubscriptionPlanId && <li>
                                                                            <button className={`dropdown-item `} onClick={() => naviget(`payment-history/${row?.userId}`)}  >Payment History</button>
                                                                        </li>
                                                                    }
                                                                    {
                                                                        (row?.assignedSubscriptionPlanId && row?.invitationStatus === "Pending") && <li>
                                                                            <button className={`dropdown-item `} onClick={() => Invitation(row)} >Resend Invite</button>
                                                                        </li>
                                                                    }
                                                                    {
                                                                        row?.assignedSubscriptionPlanId && <li>
                                                                            <button className={`dropdown-item `} onClick={() => naviget(`plan-history/${row?.userId}`)}>Plan History</button>
                                                                        </li>
                                                                    }
                                                                </ul>
                                                            </TableCell>
                                                        </TableRow>
                                                    </React.Fragment>
                                                }
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                            </>
                            :
                            <div className='d-flex justify-content-center mt-5 me-5'><h5>{permissionMessage}</h5></div>
                    }
                </div>
                <div>
                    {
                        intialRole({ permission: "Provider LIST", userData: userData, section: 'AbletoView/ListLeads' }) && <section className='w-100 d-flex justify-content-center'>
                            {
                                paginationTotalCount > 10 && <PaginationCom count={paginationTotalCount} controller={controller} setController={setController} />
                            }
                        </section>
                    }
                </div>
            </div>
            <Dialog
                open={openModal.assignPlan}
                keepMounted
                TransitionComponent={Transition}
                onClose={() => handleClosedModal("assignPlan")}
                aria-describedby="alert-dialog-slide-description"
                maxWidth="sm"
                fullWidth={true}
            >
                <DialogContent className='px-5'>
                    <div className="row">
                        <div className="col-12 text-center">
                            <p>Assign Subscription Plan</p>
                        </div>
                        <div className="col-sm-12 mb-2 mt-0">
                            <label className="form-label mb-0">Subscription Plan<span className='Star_Mark'>*</span></label>
                            <SelectBox
                                placeholder="Select Title"
                                value={assignSubscriptionPlan?.values?.subscriptionPlan}
                                name="subscriptionPlan"
                                keys="subscriptionPlanId"
                                label="title"
                                menuValeu="subscriptionPlanId"
                                option={subscriptionPlanList}
                                handleChange={(e) => assignSubscriptionPlan.setFieldValue("subscriptionPlan", e.target.value)}
                            />
                            <Errors formikfun={assignSubscriptionPlan} name={"subscriptionPlan"} />
                        </div>
                        <div className="col-12 text-end">
                            <Button onClick={() => handleSubmit("NO", "assignPlan")} > Cancel</Button>
                            <Button onClick={() => handleSubmit("YES", "assignPlan")} autoFocus>Submit</Button>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
            <Dialog
                open={openModal.assignFreePlan}
                keepMounted
                TransitionComponent={Transition}
                onClose={() => handleClosedModal("assignFreePlan")}
                aria-describedby="alert-dialog-slide-description"
                maxWidth="xs"
                fullWidth={true}
            >
                <DialogContent className='px-5'>
                    <div className="row">
                        <div className="col-12 text-center">
                            <p> <b>Assign Free  Plan</b> </p>
                        </div>
                        {/* <div className="col-12 text-center">
                            <p className='mb-1'>Are you sure?</p>
                            <p>You want to assign free  plan</p>
                        </div> */}
                        <div className="col-12 my-4 px-0">
                            <label className="form-label mb-0">End Date<span className='Star_Mark'>*</span></label>
                            <input type="date" min={minimumdate} value={assignFreePaln?.endDate} onChange={(e) => setAssignFreePaln((prev) => ({ ...prev, endDate: e.target.value, error: "" }))} className="form-control position-relative" id="datefield" />
                            <div className="error">{assignFreePaln?.error}</div>
                        </div>
                        <div className="col-12 text-end " >
                            <Button variant="outlined" onClick={() => handleSubmit("NO", "assignFreePlan")} className=' mui_button_color me-3' size='small' >Cancel</Button>
                            <Button variant="contained" onClick={() => handleSubmit("YES", "assignFreePlan")} autoFocus className=' mui_button_color' size='small'>submit</Button>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
            <Modal show={openModal?.extendFreePlan} onHide={() => { handleClosedModal("extendFreePlan") }} centered>
                <Modal.Body>
                    <div className="text-center">
                        <h5 className="modal-title mb-0">Extend Free Plan</h5>
                    </div>
                    <div className="modal-body  cus-i">
                        <div className="col-sm-12 mb-3">
                            <label className="form-label mb-0">End Date <span className='Star_Mark'>*</span></label>
                            <input type="date" min={minimumdate} className="form-control position-relative" name="endDate" id="datefield" value={extendFreePlanFun.values.endDate ? moment(extendFreePlanFun.values.endDate).format("YYYY-MM-DD") : ""}
                                onChange={(e) => extendFreePlanFun.setFieldValue("endDate", e.target.value)} />
                            <Errors formikfun={extendFreePlanFun} name={"endDate"} />
                        </div>
                        <div className="col-sm-12 mb-3">
                            <label className="form-label mb-0">Remark <span className='Star_Mark'>*</span></label>
                            <textarea type="text" className="form-control" name='remark' value={extendFreePlanFun.values.remark}
                                onChange={(e) => extendFreePlanFun.setFieldValue(`remark`, e.target.value)} />
                            <Errors formikfun={extendFreePlanFun} name={"remark"} />
                        </div>
                    </div>
                    <div className="text-center mt-3">
                        <Button variant="contained" className='me-2 mui_button_color' onClick={extendFreePlanFun.handleSubmit}>submit</Button>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={openModal?.resendInvitaion} onHide={() => { handleClosedModal("resendInvitaion") }} centered>
                <Modal.Body>
                    <div className="text-center">
                        <h5 className="modal-title mb-0">Resend Invitation</h5>
                    </div>
                    <div className="modal-body  cus-i">
                        <div className="col-sm-12 mb-3">
                            <label className="form-label mb-0">{changeEmail ? "Old Email" : "Email"} <span className='Star_Mark'>*</span></label>
                            <input disabled type="email" className="form-control" name='oldEmail' value={resendInvitationFun.values.oldEmail}
                                onChange={(e) => resendInvitationFun.setFieldValue(`oldEmail`, e.target.value)} />
                        </div>
                        <Errors formikfun={resendInvitationFun} name={"oldEmail"} />
                        <div className="d-flex justify-content-end">
                            <Button variant="contained" style={{ textTransform: "capitalize" }} onClick={() => setChangeEmail((pre) => !pre)} > {!changeEmail ? "Change email" : "Dismiss"} </Button>
                        </div>
                        {
                            changeEmail && <div className="col-sm-12 mb-3">
                                <label className="form-label mb-0">New Email<span className='Star_Mark'>*</span></label>
                                <input maxLength={50} type="email" className="form-control" name='newEmail' value={resendInvitationFun.values.newEmail}
                                    onChange={(e) => resendInvitationFun.setFieldValue(`newEmail`, e.target.value)} />
                                <Errors formikfun={resendInvitationFun} name={"newEmail"} />
                            </div>
                        }
                    </div>
                    <div className="text-center mt-3">
                        <Button variant="contained" className='me-2 mui_button_color' onClick={resendInvitationFun.handleSubmit}>submit</Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default List
