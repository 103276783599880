import React, { useEffect, useState } from 'react'
import PhoneNumber from '../../Component/PhoneInput'
import { useFormik } from 'formik'
import { Errors } from '../../Component/ErrorShow'
import { SelectBox, TextInput } from '../../Component/Inputs'
import Loader from '../../Component/Loading'
import { Button, Tab, Tabs } from '@mui/material'
import { toast } from 'react-toastify'
import { basicdetailsValidation, businesscDetailsValidation, pattern } from '../../Validation/Validation'
import { useNavigate, useParams } from 'react-router-dom'
import { GET_BASIC_DETAILS, GET_BUSINESS_DETAILS, UPDATE_BASIC_DETAILS, UPDATE_BUSINESS_DETAILS } from '../../Repository/ProviderRepo'
import { RoleTitle, TitleOptions } from '../../Constants/Constants'
import { BUSINESS_CATEGORY_LIST } from '../../Repository/BusinessCategoryRepo'
import { FaArrowLeft } from 'react-icons/fa'
const EditProvider = () => {
    const [Loading, setLoading] = useState(false)
    const [categoryList, setCategoryList] = useState([])
    const [tab, setTab] = useState("1")
    const navigate = useNavigate()
    const { id } = useParams()
    const allTabs = [
        {
            label: "Basic Details",
            value: `1`,
        },
        {
            label: "Business Details",
            value: `2`,
        },
    ];

    // get provider basice details  list 
    const getListproviderbasic = async () => {
        setLoading(true)
        const payload = {
            rolesToList: [RoleTitle?.SERVICE_PROVIDER],
            getStaffList: false,
            providerId: id,
            activeStatus: true,
        };
        try {
            let response = await GET_BASIC_DETAILS(payload)
            formik.setValues(response?.data)
            setLoading(false)
        } catch (error) {
            toast.error(error.message)
            setLoading(false)
        }
    }
    // get provider business details  list 
    const getListproviderbusiness = async () => {
        setLoading(true)
        let payload = { providerId: id }
        try {
            let response = await GET_BUSINESS_DETAILS(payload)
            upadetBusinessDetails.setValues(response.data)
            setLoading(false)
        } catch (error) {
            toast.error(error.message)
            setLoading(false)
        }
    }
    const getlistBusinessCategories = async () => {
        setLoading(true)
        let payload = {
            activeStatus: true,
        }
        try {
            let response = await BUSINESS_CATEGORY_LIST(payload)
            setCategoryList(response?.data)
        } catch (error) {
            setCategoryList([])
        }
        finally {
            setLoading(false)
        }
    }

    const formik = useFormik({
        initialValues: {
            title: "",
            firstName: "",
            lastName: "",
            mobile: "",
            email: "",
        },
        validationSchema: basicdetailsValidation,
        validateOnChange: true,
        validateOnBlur: true,
        validate: (values) => {
            const errors = {};
            if (values?.mobile?.length > 0 && (values?.mobile.match(pattern?.PHONE_NO))) {
                delete errors.mobile
            } else {
                errors.mobile = "Please enter a valid mobile number ";
            }
            return errors;
        },
        onSubmit: async (values) => {
            const { title, mobile, email, firstName, lastName, authId } = { ...values }
            let payload = {
                title,
                mobile,
                email,
                firstName,
                lastName,
                userId: id,
                authId,
            };
            setLoading(true)
            try {
                let response = await UPDATE_BASIC_DETAILS(payload)
                toast.success(response.message)
                setLoading(false)
                setTab("2")
            } catch (error) {
                toast.error(error.message)
                setLoading(false)
            }
        },
    })

    const upadetBusinessDetails = useFormik({
        initialValues: {
            businessName: "",
            businessCategoryId: "",
            about: "",
        },
        validationSchema: businesscDetailsValidation,
        validateOnChange: true,
        onSubmit: async (values) => {
            let payload = {
                userId: id,
                businessName: values.businessName,
                about: values.about,
                businessCategoryId: values.businessCategoryId,
                businessProfileId: values?.BusinessProfileId
            };
            setLoading(true)
            try {
                let response = await UPDATE_BUSINESS_DETAILS(payload)
                toast.success(response.message)
                setLoading(false)
                navigate("/layout/provider")
            } catch (error) {
                toast.error(error.message)
                setLoading(false)
            }
        },
    })
    const handleChangeInput = (event) => {
        const { name, value } = event.target
        formik.setFieldValue(name, value)
    }
    const handleChangeBusinessInput = (event) => {
        const { name, value } = event.target
        upadetBusinessDetails.setFieldValue(name, value)
    }
    useEffect(() => {
        getlistBusinessCategories()
    }, [])

    useEffect(() => {
        if (tab === "1") {
            getListproviderbasic()
        } else {
            getListproviderbusiness()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tab])

    return (
        <>
            {Loading && <Loader />}
            <section className='d-flex align-items-center'>
                <FaArrowLeft className='BACK_ICON' onClick={() => navigate(-1)} />
                <h3 className='PAGE-NAME'>Update Provider</h3>
            </section>
            <div className="container-fluid shadow py-3">
                <Tabs variant="scrollable" scrollButtons="auto" onChange={(event, newValue) => setTab(newValue)} value={tab} aria-label="scrollable auto tabs example">
                    {allTabs.map((item, i) =>
                        <Tab key={i}
                            style={{ textTransform: "capitalize" }}
                            label={item.label}
                            value={item.value}
                            to={item.value}
                        />
                    )}
                </Tabs>
                {
                    tab === "1" && <form className="row g-3 mt-3" onSubmit={formik.handleSubmit} >
                        <div className="col-sm-6 mb-2 mt-0">
                            <label className="form-label mb-0">Title<span className='Star_Mark'>*</span></label>
                            <SelectBox
                                placeholder="Select Title"
                                value={formik?.values?.title?.trim()}
                                name="title"
                                keys="value"
                                label="label"
                                menuValeu="value"
                                handleChange={(e) => formik.setFieldValue("title", e.target.value)}
                                option={TitleOptions}
                            />
                            <Errors formikfun={formik} name={"title"} />
                        </div>
                        <div className="col-sm-6 mb-2 mt-0">
                            <label className="form-label mb-0">First Name<span className='Star_Mark'>*</span></label>
                            <TextInput
                                handleChange={handleChangeInput}
                                maxLength={50}
                                name="firstName"
                                value={formik.values.firstName}
                                type="text"
                                size='small'
                            />
                            <Errors formikfun={formik} name={"firstName"} />
                        </div>
                        <div className="col-sm-6 mb-2 mt-0">
                            <label className="form-label mb-0">Last Name<span className='Star_Mark'>*</span></label>
                            <TextInput
                                handleChange={handleChangeInput}
                                maxLength={50}
                                name="lastName"
                                value={formik.values.lastName}
                                type="text"
                                size='small'
                            />
                            <Errors formikfun={formik} name={"lastName"} />
                        </div>
                        <div className="col-sm-6 mb-2 ms-0 mt-0 mobile_number_input_box">
                            <label className="form-label mb-0">Mobile Number<span className='Star_Mark'>*</span></label>
                            <div className="input_group_mobile mb-0">
                                <PhoneNumber
                                    width={false}
                                    Require={false}
                                    Name="mobile"
                                    HandleChange={handleChangeInput}
                                    Value={formik.values.mobile}
                                    disabledInput={true}
                                />
                            </div>
                            <Errors formikfun={formik} name={"mobile"} />
                        </div>
                        <div className="col-sm-6 mb-2 mt-0">
                            <label className="form-label mb-0">Email Address<span className='Star_Mark'>*</span></label>
                            <TextInput
                                handleChange={handleChangeInput}
                                maxLength={50}
                                name="email"
                                value={formik.values.email}
                                type="email"
                                size='small'
                                disable={true}
                            />
                            <Errors formikfun={formik} name={"email"} />
                        </div>
                        <div className="col-12 text-end">
                            <Button variant="contained" type="submit" className="my-3 mui_button_color">Update</Button>
                        </div>
                    </form>
                }
                {
                    tab === "2" && <form className="row g-3 mt-4" onSubmit={upadetBusinessDetails.handleSubmit} >
                        <div className="col-sm-6 mb-2 mt-0">
                            <label className="form-label mb-0">Business Name<span className='Star_Mark'>*</span></label>
                            <TextInput
                                handleChange={handleChangeBusinessInput}
                                maxLength={100}
                                name="businessName"
                                value={upadetBusinessDetails.values.businessName}
                                type="text"
                                size='small'
                            />
                            <Errors formikfun={upadetBusinessDetails} name={"businessName"} />
                        </div>
                        <div className="col-sm-6 mb-2 mt-0">
                            <label className="form-label mb-0">Business Category<span className='Star_Mark'>*</span></label>
                            <SelectBox
                                placeholder="Select Title"
                                value={upadetBusinessDetails?.values?.businessCategoryId}
                                name="title"
                                keys="businessCategoryId"
                                label="categoryName"
                                menuValeu="businessCategoryId"
                                handleChange={(e) => upadetBusinessDetails.setFieldValue("businessCategoryId", e.target.value)}
                                option={categoryList}
                            />
                            <Errors formikfun={upadetBusinessDetails} name={"businessCategoryId"} />
                        </div>
                        <div className="col-sm-12 mb-2 mt-0">
                            <label className="form-label mb-0">About Us <span className='Star_Mark'>*</span></label>
                            <textarea className="form-control INPUT-STYLE" cols="30" name="about" value={upadetBusinessDetails.values.about}
                                onChange={handleChangeBusinessInput}>
                            </textarea>
                            <Errors formikfun={upadetBusinessDetails} name={"about"} />
                        </div>
                        <div className="col-12 text-end">
                            <Button variant="contained" type="submit" className="my-3 mui_button_color">update</Button>
                        </div>
                    </form>
                }

            </div>
        </>
    )
}

export default EditProvider

// categoryList